<template>
    <v-container id="schedule" ref="schedule">
        <h1>Расписание</h1>
        <v-card elevation="15" class="mt-4 pb-2">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr class="table-head">
                        <th class="text-left table-head-cell">
                            <h2>День</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Leto</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Panda Voley</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Match Point</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Global Sport</h2>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in schedule"
                        :key="item.name"
                        >
                        <td>{{ item.name }}</td>
                        <td>{{ item.leto }}</td>
                        <td>{{ item.panda }}</td>
                        <td>{{ item.match }}</td>
                        <td>{{ item.global }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
            schedule:[
                {name: "Понедельник", leto: "", panda: "", match: "", global: "19:00, 21:00"},
                {name: "Вторник", leto: "18:45, 20:15", panda: "21:00", match: "", global: ""},
                {name: "Среда", leto: "20:30", panda: "", match: "21:00", global: ""},
                {name: "Четверг", leto: "", panda: "21:00", match: "19:00", global: "19:00, 21:00"},
                {name: "Пятница", leto: "19:00", panda: "", match: "", global: ""},
                {name: "Суббота", leto: "", panda: "", match: "", global: ""},
                {name: "Воскресенье", leto: "17:30", panda: "", match: "", global: ""},
            ]
        }
    }
}
</script>
<style scoped>
.table-head{
    background: #0d47a0;
}
.table-head-cell{
    color: white !important;
}
</style>