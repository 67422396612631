<template>
    <v-container class="d-flex justify-center align-center" style="height: 100vh">
        <v-card elevation="15" class="pa-4" style="width: 100%; max-width: 500px">
            <h1>Авторизация</h1>
            <v-form v-model="valid" class="mt-2">
            <v-text-field
                :rules="[rules.required, rules.min, rules.max]"
                color="blue darken-4"
                outlined
                dense
                v-model="login"
                label="Логин"
            />
            <v-text-field
                  dense
                  v-model="password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, rules.max]"
                  :type="show ? 'text' : 'password'"
                  label="Пароль"
                  outlined
                  @click:append="show = !show">
              </v-text-field>
            </v-form>
            <v-btn color="blue darken-4" class="white--text ma-auto d-block" :disabled="!valid" @click="authorization">Авторизация</v-btn>
        </v-card>
    </v-container>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    data () {
      return {
        login: "",
        password: "",
        show: false,
        focus: false,
        valid: false,
        loading: false,
        rules: {
          required: value => !!value || 'Это поле обязательно.',
          min: v => v.length >= 2 || 'Минимальная длина 5 символов',
          max: v => v.length <= 20 || 'Максимальная длина 15 символов',
        },
      }
    },
    computed:mapGetters(['adminLoginStatus']),
    watch:{
        adminLoginStatus: function(val){
            if(val === "OK"){
                window.location.href = 'admin'
            }
            if(val === "ERROR"){
                alert("wrong data");
            }
        }
    },
    methods:{
        ...mapActions(['enableHeader', 'enableVideo', 'enableFooter', 'auth']),
        authorization(){
            var userdata = new Object;
            userdata.login = this.login;
            userdata.password = this.password;
            this.auth(userdata);
        }
    },
    mounted(){
        if(this.adminLoginStatus === "OK"){
            window.location.href = 'admin'
        }
    },
    beforeMount(){
        this.enableHeader(false);
        this.enableVideo(false);
        this.enableFooter(false);  

    },
    beforeDestroy(){
        this.enableHeader(true);
        this.enableVideo(true);
        this.enableFooter(true);
    }
}
</script>