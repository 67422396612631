<template>
    <v-container id="locations" ref="locations">
        <h1>Пляжные центры</h1>
        <v-card elevation="15" class="mt-4">
            <v-tabs v-model="tab"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows>
                <v-tab
                    color="blue darken-4"
                    v-for="item in items" 
                    :key="item.id">
                    {{item.title}}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item 
                    v-for="item in items"
                    :key="item.id">
                    <v-container fluid class="pa-0">
                        <v-row no-gutters>
                            <v-col cols="12" md="4">
                                <v-img :src="item.img" width="100%" aspect-ratio="1"/>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-container class="pt-sm-0 pt-xs-4 mb-4 px-4">
                                    <h2>{{item.content}}</h2>
                                    <h3>Расписание</h3>
                                    <v-card 
                                        elevation="0"
                                        tile
                                        class="mt-1"
                                        v-html="item.schedule">
                                    </v-card>
                                    <h3 class="mt-2">Адрес</h3>
                                    <v-card 
                                        elevation="0"
                                        tile
                                        class="mt-1"
                                        v-html="item.adress">
                                    </v-card>
                                    <div class="d-flex mt-2">
                                        <v-btn color="yellow darken-1" :href="item.link" target="_blank">Маршрут</v-btn>
                                        <v-btn class="ml-2 white--text" color="blue darken-4">Записаться</v-btn>
                                    </div>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                    
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
            tab: null,
            items:[
                {
                    id: 1, title: "Leto", content: "Пляжный центр «Лето»", 
                    img: require("@/assets/locations/leto-min.jpg"),
                    schedule: "Вт: 18:45, 20:15<br>Ср: 20:30<br>Пт: 19:00<br>Вск: 17:30, 19:10, 20:30",
                    adress: "м. Полежаевская<br>Москва, проспект Маршала Жукова, д. 4, стр. 2",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.777413%2C37.500980&rtt=taxi"},
                {
                    id: 2, title: "Panda Voley", content: "Пляжный центр «Panda Volley Hall»", 
                    img: require("@/assets/locations/panda-min.jpg"),
                    schedule: "Вт: 21:00<br>Ср: 13:00<br>Чт: 21:00",
                    adress: "м. Ленинский проспект<br>Москва, 5-й донской проезд, 15 стр. 7",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.705417%2C37.600415&rtt=taxi"},
                {   
                    id: 3, title: "Match Point", content: "Пляжный центр «Match Point Перово»", 
                    img: require("@/assets/locations/perovo_2.jpg"), 
                    schedule: "Ср: 21:00<br>Чт: 19:00",
                    adress: "м. Перово<br>Москва, 1 пр-д Перова Поля, 9 стр. 7",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.750266%2C37.772693&rtt=taxi"},
                {
                    id: 4, title: "Global Sport", content: "Пляжный центр «Global Sport Arena»", 
                    img: require("@/assets/locations/pav.jpg"), 
                    schedule: "Пн 19:00, 21:00<br>Чт: 19:00, 21:00",
                    adress: "м. Павелецкая/Тульская<br>Москва, Дубининская ул., 69",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.714926%2C37.634604&rtt=taxi"},
            ]
        }
    }
}
</script>