<template>
    <div>
        <v-card 
            elevation="15" 
            rounded="md"
            class="pa-4 form-card"
            v-if="status === 'TODO' || status === 'ERROR'"
        >
        <h2 class="blue--text text--darken-4">Открыт набор! <br>Первое занятие {{dueDate}}</h2>
        <v-form v-model="valid" class="pt-2">
            <v-text-field
            :rules="[rules.required, rules.min, rules.max]"
            color="blue darken-4"
            outlined
            dense
            v-model="name"
            label="Ваше имя"
            />
            <v-text-field-simplemask
            color="blue darken-4"
            v-model="phone"
            :label="label"
            v-bind:properties="{
                prefix: '+7',
                suffix: '',
                readonly: false,
                disabled: false,
                outlined: true,
                dense: true,
                clearable: true,
                placeholder: '',
                required: true
            }"
            v-bind:options="{
                inputMask: '(###) ###-##-##',
                outputMask: '##########',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
            }"
            v-bind:focus="focus"
            v-on:focus="focus = false"
            />
        </v-form>
        <div class="d-flex justify-end align-center">
            <h5 class="blue-gray--text">До конца регистрации {{daysUntil}} д.!</h5>
            <v-btn color="blue darken-4" class="ml-2 white--text" :disabled="!valid" :loading="loading" @click="send">Отправить</v-btn>
        </div>
        </v-card>
        <v-card v-else elevation="15" 
            rounded="md"
            class="pa-4 form-card">
            <h2>Скоро вам перезвонят</h2>
        </v-card>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
  export default {
    name: 'Main',
    data () {
      return {
        phone: "",
        name: "",
        focus: false,
        valid: false,
        label: "Номер телефона",
        loading: false,
        rules: {
          required: value => !!value || 'Это поле обязательно.',
          min: v => v.length >= 2 || 'Минимальная длина 5 символов',
          max: v => v.length <= 15 || 'Максимальная длина 15 символов',
        },
      }
    },
    computed: {
        ...mapGetters(['status', 'registrationDueDate', 'dueDateStatus']),
        dueDate(){
            if (!this.registrationDueDate) return null
            const dateTime = new Date(this.registrationDueDate * 1000);
            const [month, day] = [dateTime.getMonth() - 1, dateTime.getDate()]
            const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
                "июля", "августа", "сентября", "октября", "ноября", "декабря"
                ];
            return `${day} ${monthNames[month]}`
        },
        daysUntil(){
            if (!this.registrationDueDate) return null
            const dateTime = new Date(this.registrationDueDate * 1000);
            const today = new Date();
            const diffTime = Math.abs(dateTime - today);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        }
    },
    async mounted(){
        if(this.dueDateStatus !== "OK"){
            this.loading = true;
            await this.fetchRegistrationDueDate();
            this.loading = false;
        }
    },
    methods:{
      ...mapActions(['sendUser', 'fetchRegistrationDueDate']),
      async send(){
        this.loading = true;
        console.log(this.name)
        await this.sendUser({name: this.name, phone: this.phone});
        console.log(this.phone)
        this.loading = false;
      }
    }
  }
</script>
<style scoped>
.form-card{
  background: rgba(255,255,255, .9) !important;
}
</style>