export default{
  actions: {
    async sendUser({commit}, userdata){
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" }
      };
      const postUrl="https://api.vball.moscow/users/" + userdata.name + "/" + userdata.phone;
      const res = await fetch(postUrl, requestOptions); 
      const result = await res.json();
      commit('updateStatus', result);
    },
    async getUsers({commit}){
      const res = await fetch("https://api.vball.moscow/users/9d81e96d-c32d-4641-bf76-f1844c5f2529"); 
      const users = await res.json();
      commit("updateUsers", users);
    }
  },
  mutations: {
    updateStatus(state, result){
      state.status = result.status
    },
    updateUsers(state, users){
      state.users = users
    }
  },
  state: {
    status : "TODO",
    users: []
  },
  getters: {
    status(state){
      return state.status
    },
    users(state){
      return state.users
    }
  }
}
