<template>
    <v-container style="height: 70vh" class="d-flex justify-center align-center">
        <v-card class="pa-4">
            <h1>Такой страницы не существует</h1>
        </v-card>
    </v-container>
</template>
<script>
import {mapActions} from 'vuex'
export default {
    methods: mapActions(['enableVideo']),
    beforeMount(){
        this.enableVideo(false);
    },
    beforeDestroy(){
        this.enableVideo(true);
    }
}
</script>