<template>
  <v-app id="app">
    <VideoBg v-if="isVideoEnabled"/>
    <v-app-bar 
      v-if="isHeaderEnabled"
      app
      class="app-bar"
      id="app-bar"
      elevate-on-scroll
      >
        <v-container class="app-bar-content">
          <v-toolbar-title><v-btn icon href="/"><v-img width="54" src="@/assets/logo.png"></v-img></v-btn></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small tile class="white--text" href="/map">Карта</v-btn>
          <v-btn text small tile class="white--text" @click="schedule">Рассписание</v-btn>
          <v-btn text small tile class="white--text" @click="price">Цены</v-btn>
        </v-container>
    </v-app-bar>
    <v-main>
        <router-view></router-view>
    </v-main>
    <Footer class="mt-10" v-if="isFooterEnabled"/>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import goTo from 'vuetify/es5/services/goto'
import VideoBg from "@/components/MainPage/VideoBg"
import Footer from "@/components/MainPage/Footer"
export default {
  name: 'App',
  components:{
    VideoBg, Footer
  },
  data: () => ({
    //
  }),
  methods:{
    schedule(){
      goTo(document.getElementById('schedule'));
    },
    price(){
      goTo(document.getElementById('prices'));
    },
  },
  computed:{...mapGetters(["isHeaderEnabled", 'isVideoEnabled', 'isFooterEnabled'])},
  mounted(){
    var mainContainer = document.getElementById('main-container');
    var appBarHeight = document.getElementById('app-bar') ? document.getElementById('app-bar').offsetHeight : 0;
    var bgVidHeight = document.getElementById('bgvid') ? document.getElementById('bgvid').offsetHeight : 0;
    if(mainContainer){
      mainContainer.style.height = (bgVidHeight - appBarHeight) + "px";
    }
  }
};
</script>
<style>
@import url('./fonts/stylesheet.css');

#app{
    font-family: 'URW Geometric' !important;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    color: #3a4b68;
    background: #fff;
}
.app-bar{
  background: rgba(0,0,0, .5) !important;
}
.app-bar-content{
    align-items: center;
    display: flex;
    position: relative;
    z-index: 0;
}
@media (min-width: 1904px){
  .container {
    max-width: 1185px !important;
  }
}

</style>
