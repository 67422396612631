<template>
<div>
    <video  v-if="$vuetify.breakpoint.xs"
        poster="@/assets/video/video_img_vertical.jpg" 
        id="bgvid" class="bg-video" playsinline autoplay muted loop
        @play="video_loaded">
      <source src="@/assets/video/bg_vertical.mp4" type="video/mp4">
    </video>
    <video  v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
        poster="@/assets/video/video_img_horizontal.jpg" 
        id="bgvid" class="bg-video" playsinline autoplay muted loop
        @play="video_loaded">
      <source src="@/assets/video/bg_horizontal.mp4" type="video/mp4">
    </video>
    <video  v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
        poster="@/assets/video/video_img_horizontal_big.jpg" 
        id="bgvid" class="bg-video" playsinline autoplay muted loop
        @play="video_loaded">
      <source src="@/assets/video/bg_horizontal_big.mp4" type="video/mp4">
    </video>
</div>
</template>
<script>
export default {
  methods:{
      video_loaded(){

        var mainContainer = document.getElementById('main-container');
        if(mainContainer){
          mainContainer.style.height = (document.getElementById('bgvid').offsetHeight - document.getElementById('app-bar').offsetHeight) + "px"
        }
        
      }
    },
};
</script>
<style scoped>
@media screen and (min-width: 300px){
  .bg-video{
    position: absolute;
    min-height: 667px; 
    width:100% !important;
    max-height: 120vh;
  }
}
@media screen and (min-width: 900px){
  .bg-video{
    position: absolute;
    min-height: 300px; 
    width:100% !important;
    max-height: 120vh;
  }
}

</style>