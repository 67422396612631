<template>
  <v-footer
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-title class="yellow darken-1">
          <v-container class="d-flex justify-space-between px-0">
                <h3 class="black--text smm-title">Подключайся к нашим соцсетям</h3>
                <div>
                    <v-btn
                    v-for="icon in icons"
                    :key="icon.icon"
                    class="mx-4"
                    icon
                    :href="icon.link"
                    >
                    <v-icon color="black" size="24px">
                        {{ icon.icon }}
                    </v-icon>
                    </v-btn>
                </div>
                
          </v-container>
      </v-card-title>
      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} — <strong>Школа волейбола V'ball</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      icons: [
        {
          icon:'mdi-instagram',
          link:'https://www.instagram.com/vball_moscow/',
        },
        {
          icon:'mdi-telegram',
          link:'https://t.me/vballmsk',
        },
        {
          icon:'mdi-whatsapp',
          link:'https://whatsapp.com',
        },
        {
          icon:'mdi-vk',
          link:'https://vk.com/im?sel=-164644495',
        }
      ],
    }),
  }
</script>
<style scoped>
.smm-title{
  word-break: break-word;
}
</style>