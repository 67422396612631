var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.status === 'TODO' || _vm.status === 'ERROR')?_c('v-card',{staticClass:"pa-4 form-card",attrs:{"elevation":"15","rounded":"md"}},[_c('h2',{staticClass:"blue--text text--darken-4"},[_vm._v("Открыт набор! "),_c('br'),_vm._v("Первое занятие "+_vm._s(_vm.dueDate))]),_c('v-form',{staticClass:"pt-2",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.min, _vm.rules.max],"color":"blue darken-4","outlined":"","dense":"","label":"Ваше имя"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field-simplemask',{attrs:{"color":"blue darken-4","label":_vm.label,"properties":{
            prefix: '+7',
            suffix: '',
            readonly: false,
            disabled: false,
            outlined: true,
            dense: true,
            clearable: true,
            placeholder: '',
            required: true
        },"options":{
            inputMask: '(###) ###-##-##',
            outputMask: '##########',
            empty: null,
            applyAfter: false,
            alphanumeric: false,
            lowerCase: false,
        },"focus":_vm.focus},on:{"focus":function($event){_vm.focus = false}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('div',{staticClass:"d-flex justify-end align-center"},[_c('h5',{staticClass:"blue-gray--text"},[_vm._v("До конца регистрации "+_vm._s(_vm.daysUntil)+" д.!")]),_c('v-btn',{staticClass:"ml-2 white--text",attrs:{"color":"blue darken-4","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.send}},[_vm._v("Отправить")])],1)],1):_c('v-card',{staticClass:"pa-4 form-card",attrs:{"elevation":"15","rounded":"md"}},[_c('h2',[_vm._v("Скоро вам перезвонят")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }