<template>
    <v-dialog v-model="formModal" persistent @click:outside="close" max-width="600">
        <SendForm/>
        <v-container class="mt-3">
            <h3 class="black--text text-center">Остались вопросы? Свяжись со мной!</h3>
            <v-container class="d-flex px-0 justify-center" >
                <v-btn icon href="https://www.instagram.com/vball_moscow/" target="_blank"><v-icon color="black">mdi-instagram</v-icon></v-btn>
                <v-btn icon href="https://t.me/vballmsk" target="_blank"><v-icon color="black"> mdi-telegram</v-icon></v-btn>
                <v-btn icon href="https://whatsapp.com" target="_blank"><v-icon color="black">mdi-whatsapp</v-icon></v-btn>
                <v-btn icon href="https://vk.com/im?sel=-164644495" target="_blank"><v-icon color="black">mdi-vk</v-icon></v-btn>
            </v-container>
        </v-container>
        
    </v-dialog>
</template>
<script>
import SendForm from "@/components/MainPage/SendForm"
import {mapActions, mapGetters} from 'vuex'
export default {
    computed: mapGetters(['formModal']),
    methods: {
        ...mapActions(['toggleFormModal']),
        close(){
            this.toggleFormModal()
        }
    },
    components:{
        SendForm
    }
}
</script>
<style>
.v-dialog{
    background: #fff176 !important;
}
</style>